import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, GENDER_TYPES } from "../../../../config/const";
import { AggregateTypeTextDef, GenderTypeTextDef } from "../../../../config/text-def";
import { ChartQueryResult } from "../../../../dashboard-api";
import { AggregateTypeRow, ChartSeriesColumnOption } from "../../../../types";
import {
  BaseGenderTypeColumnChart,
  BaseGenderTypeColumnChartSeriesCode,
  BaseGenderTypeColumnData,
} from "../base-gender-type-column-chart";

export class RatioAgeDistributionByGenderChart extends BaseGenderTypeColumnChart {
  protected _createSeriesDef(t: TFunction): Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption> {
    const results = new Map<BaseGenderTypeColumnChartSeriesCode, ChartSeriesColumnOption>();

    AGGREGATE_TYPES.forEach((aggregateType) => {
      GENDER_TYPES.forEach((genderType, i) => {
        if (genderType === "all") {
          return;
        }
        const key = `${aggregateType}_${genderType}` as BaseGenderTypeColumnChartSeriesCode;
        results.set(key, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            GenderTypeTextDef.get(genderType) as string
          )}`,
          color: super.getColor(aggregateType, i),
        });
      });
    });

    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGenderTypeColumnData> | ChartQueryResult<BaseGenderTypeColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: GENDER_TYPES.filter((genderType) => !(aggregateType === "industry_average" && genderType === "all")).map(
        (genderType) => ({
          header: t(GenderTypeTextDef.get(genderType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.genderType === genderType
          ),
        })
      ),
    }));
  }
}
