import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBeforeUnload = (value: ((event: BeforeUnloadEvent) => any) | string) => {
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      const returnValue = typeof value === "function" ? value(event) : value;
      if (returnValue) {
        event.preventDefault();
        event.returnValue = returnValue;
      }
      return returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [value]);
};

export default useBeforeUnload;
