import dayjs from "dayjs";

const DISPLAY_FORMAT = "YYYY/MM/DD";
const DISPLAY_DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";
const MUTATE_FORMAT = "YYYY-MM-DD";

/**
 * YYYY-MM-DD(Mutateのフォーマット) を受け取り、YYYY/MM/DD(Displayのフォーマット) で返す
 * @param dateStr YYYY-MM-DD(Mutateのフォーマット)
 * @returns YYYY/MM/DD(Displayのフォーマット)
 */
const formatDisplayFromMutate = (dateStr: string): string => {
  return dayjs(dateStr).format(DISPLAY_FORMAT);
};

/**
 * YYYY-MM-DD HH:mm(Mutateのフォーマット) を受け取り、YYYY/MM/DD HH:mm(Displayのフォーマット) で返す
 * @param dateStr YYYY-MM-DD HH:mm(Mutateのフォーマット)
 * @returns YYYY/MM/DD HH:mm(Displayのフォーマット)
 */
const formatDisplayDateTimeFromMutate = (dateStr: string): string => {
  return dayjs(dateStr).format(DISPLAY_DATE_TIME_FORMAT);
};

/**
 * Date型を受け取り、YYYY-MM-DD(Mutateのフォーマット) で返す
 * @param date Date型
 * @returns YYYY-MM-DD(Mutateのフォーマット)
 */
const formatMutate = (date: Date): string => {
  return dayjs(date).format(MUTATE_FORMAT);
};

/**
 * YYYY-MM-DD(Mutateのフォーマット) を受け取り、Date型で返す
 * @param dateStr YYYY-MM-DD(Mutateのフォーマット)
 * @returns Date型
 */
const formatFromMutate = (dateStr: string): Date => {
  return dayjs(dateStr).toDate();
};

export const dateUtil = {
  DISPLAY_FORMAT,
  formatDisplayFromMutate,
  formatDisplayDateTimeFromMutate,
  formatMutate,
  formatFromMutate,
};
