import { useState } from "react";
import { ChartGroup } from "../../../types";
import { CHART_COMPOSITION_MASTER } from "../config/const";
import { ChartNode, ChartRoot } from "../types";

export const useDashboardChartSelect = (chartNodeId: ChartGroup | "root") => {
  const [currentNodeId, setCurrentNodeId] = useState(chartNodeId);

  const getChildrenById = (
    id: ChartGroup | "root",
    node: ChartNode | ChartRoot = CHART_COMPOSITION_MASTER
  ): ChartNode[] | undefined => {
    if (node.id === id && (node.type === "group" || node.type === "root")) {
      return node.children;
    }
    if (node.type === "group" || node.type === "root") {
      for (const child of node.children) {
        const result = getChildrenById(id, child);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };

  const getChartNodePath = (
    id: ChartGroup,
    node: ChartNode | ChartRoot = CHART_COMPOSITION_MASTER
  ): ("root" | ChartGroup)[] => {
    if (node.id === id) {
      return [node.id];
    }
    if (node.type === "group" || node.type === "root") {
      for (const child of node.children) {
        const result = getChartNodePath(id, child);
        if (result.length > 0) {
          return [node.id, ...result];
        }
      }
    }
    return [];
  };

  return {
    currentNodeId,
    setCurrentNodeId,
    getChildrenById,
    getChartNodePath,
  };
};
