import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Highcharts from "highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components/loading/loading";
import { StyledAccordion } from "../../../components/styled/styled-accordion";
import { StyledAccordionDetails } from "../../../components/styled/styled-accordion-detail";
import { StyledAccordionSummary } from "../../../components/styled/styled-accordion-summary";
import { ChartTextDef } from "../../../config/text-def";
import { Chart, SegmentChart } from "../../../types";
import { IBaseSegmentChart } from "../classes/i-base-segment-chart";
import { CHART_HEIGHT_IN_BOARD, CHART_MARGIN_BOTTOM, CHART_TITLE_HEIGHT } from "../config/const";
import { SegmentChartLegendTextDef } from "../config/text-def";
import { dashboardApi, DashboardSegmentChartGetRequest } from "../dashboard-api";
import { DashboardStore } from "../stores/dashboard-store";
import { ChartType } from "../types";
import { DashboardChartDisplaySwitchModel } from "./dashboard-chart-display-switch";
import { DashboardFormula } from "./dashboard-formula";

NoDataToDisplay(Highcharts); // ReactだとこれがないとnoDataプロパティが効かない

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: CHART_TITLE_HEIGHT + CHART_HEIGHT_IN_BOARD + CHART_MARGIN_BOTTOM,
  padding: theme.spacing(1),
}));
StyledPaper.defaultProps = {
  square: true,
};

type DashboardSegmentChartInBoardProps<TChartClass extends IBaseSegmentChart> = {
  chartInstance: TChartClass;
  chart: SegmentChart;
  type: ChartType;
  edit: boolean;
  filter: DashboardSegmentChartGetRequest;
  displaySwitch: DashboardChartDisplaySwitchModel;
  onLoaded: (chart: Chart) => void;
  onClickDelete: (chart: Chart) => void;
};

const _DashboardSegmentChartInBoard = <TChartClass extends IBaseSegmentChart>({
  chartInstance,
  chart,
  type,
  edit,
  filter,
  displaySwitch,
  onLoaded,
  onClickDelete,
}: DashboardSegmentChartInBoardProps<TChartClass>) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [noData, setNoData] = useState(true);
  const [formulaOpen, setFormulaOpen] = useState(false);
  const { setDisplaySwitchDisabled } = DashboardStore.useContainer();
  const useSettingsQuery = dashboardApi.useSetting();
  const useSegmentChartsQuery = dashboardApi.useSegmentCharts(chart, filter);

  useEffect(() => {
    if (!useSettingsQuery.isLoading && !useSegmentChartsQuery.isLoading && !useSegmentChartsQuery.isFetching) {
      onLoaded(chart);
    }
  }, [useSettingsQuery.isLoading, useSegmentChartsQuery.isLoading, useSegmentChartsQuery.isFetching, chart, onLoaded]);

  useEffect(() => {
    if (useSegmentChartsQuery.data) {
      const noData = chartInstance.noData(useSegmentChartsQuery.data);
      setNoData(noData);
      setDisplaySwitchDisabled((prev) => prev && noData);
    }
  }, [useSegmentChartsQuery.data, setDisplaySwitchDisabled, chartInstance]);

  const handleDelete = () => {
    onClickDelete(chart);
  };

  const renderLoadingGraph = () => (
    <>
      <StyledPaper>
        <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
          <Typography>{t(ChartTextDef.get(chart) as string)}</Typography>
        </Box>
        <Loading my={16} />
      </StyledPaper>
    </>
  );

  const renderLoadingTable = () => (
    <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
      <StyledAccordionSummary my={0}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>{t(ChartTextDef.get(chart) as string)}</Typography>
          </Grid>
        </Grid>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box mt={-4} display="flex" justifyContent="center" alignItems="center">
          <Loading my={1} />
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );

  if (useSettingsQuery.isLoading || useSegmentChartsQuery.isLoading || useSegmentChartsQuery.isFetching)
    return type === "graph" ? renderLoadingGraph() : renderLoadingTable();

  if (!useSettingsQuery.data || !useSegmentChartsQuery.data) return null;

  const renderGraph = () => {
    return (
      <>
        <StyledPaper>
          <Box display="flex" justifyContent="space-between" alignItems="center" mx={1}>
            <Typography my={edit ? 0 : 1}>{t(ChartTextDef.get(chart) as string)}</Typography>
            {edit && (
              <IconButton onClick={handleDelete}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartInstance.getChartOptions(t, useSegmentChartsQuery.data, displaySwitch)}
            ref={chartComponentRef}
          />
        </StyledPaper>
      </>
    );
  };

  const renderTable = () => {
    return (
      <StyledAccordion defaultExpanded sx={{ boxShadow: "none" }}>
        <StyledAccordionSummary my={0}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>{t(ChartTextDef.get(chart) as string)}</Typography>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {noData ? (
            <Box mt={-4} display="flex" justifyContent="center" alignItems="center">
              <Typography color={(theme) => theme.palette.grey[500]}>{t("no-data")}</Typography>
            </Box>
          ) : (
            <Paper square>
              <TableContainer sx={{ maxWidth: "50%", tableLayout: "fixed" }}>
                <Table>
                  <TableHead sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          minWidth: "20%",
                          borderLeft: `1px solid rgb(224, 224, 224)`,
                        }}
                      >
                        {t(SegmentChartLegendTextDef.get(useSegmentChartsQuery.data.legend) as string)}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
                    {useSegmentChartsQuery.data.datasets.map((dataset, index) => (
                      <TableRow
                        key={dataset.segmentId}
                        sx={{
                          bgcolor: index % 2 === 0 ? "common.white" : (theme) => theme.palette.grey[100],
                        }}
                      >
                        <TableCell
                          sx={{
                            width: "80%",
                            overflow: "hidden",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {dataset.index}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: "20%",
                            borderLeft: `1px solid rgb(224, 224, 224)`,
                            minWidth: "20%",
                            overflow: "hidden",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {dataset.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </StyledAccordionDetails>
        <DashboardFormula open={formulaOpen} onClose={() => setFormulaOpen(false)} chart={chart} />
      </StyledAccordion>
    );
  };

  return type === "graph" ? renderGraph() : renderTable();
};

export const DashboardSegmentChartInBoard = memo(_DashboardSegmentChartInBoard) as typeof _DashboardSegmentChartInBoard;
