const getPixel = (str: string, fontSize = 14): number => {
  const fullWidthCoefficient = 1.0; // 全角文字の係数
  const halfWidthCoefficient = 0.5; // 半角文字の係数

  let estimatedWidth = 0;

  for (const char of str) {
    if (isFullWidthChar(char)) {
      estimatedWidth += fontSize * fullWidthCoefficient;
    } else {
      estimatedWidth += fontSize * halfWidthCoefficient;
    }
  }

  return estimatedWidth;
};

const isFullWidthChar = (char: string) => {
  const code = char.charCodeAt(0);
  return (
    (code >= 0x3000 && code <= 0x30ff) || // CJKの記号および句読点
    (code >= 0x4e00 && code <= 0x9fff) || // CJKの統合漢字
    (code >= 0xff00 && code <= 0xffef)
  ); // 半角・全角の形式
};

const calculateTextWidth = (text: string): number => {
  let width = 0;
  // eslint-disable-next-line no-control-regex
  const fullWidthCharRegex = /[^\x00-\x7F]/;
  for (const char of text) {
    if (fullWidthCharRegex.test(char)) {
      width += 1.6; // 全角文字
    } else if (/[A-Z]/.test(char)) {
      width += 1.2; // 英語大文字
    } else if (/[0-9]/.test(char)) {
      width += 1.05; // 数字
    } else {
      width += 0.93; // 英語小文字
    }
  }
  return width * 10;
};
export const stringWidthUtil = {
  getPixel,
  calculateTextWidth,
};
