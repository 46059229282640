import BarChartIcon from "@mui/icons-material/BarChart";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Folder from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ChartGroupTextDef, ChartTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";
import { useDashboardChartSelect } from "../hooks/use-dashboard-chart-select";

export const DashboardChartSelectPane = ({ onClickChart }: { onClickChart: (selectedChartId: Chart) => void }) => {
  const { t } = useTranslation();
  const { currentNodeId, setCurrentNodeId, getChildrenById, getChartNodePath } = useDashboardChartSelect("root");
  const onClickAddChart = (chartId: Chart) => {
    onClickChart(chartId);
  };
  return (
    <Paper square sx={{ flexGrow: 1, maxWidth: "100%" }}>
      <Grid container>
        {currentNodeId !== "root" && (
          <>
            <Grid item width={"100%"}>
              <Box pl={2} pt={1}>
                <Breadcrumbs separator="›">
                  {getChartNodePath(currentNodeId)
                    .filter((nodeId) => nodeId !== currentNodeId)
                    .map((nodeId) => (
                      <Link
                        key={nodeId}
                        component="button"
                        onClick={() => setCurrentNodeId(nodeId)}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {nodeId === "root" ? (
                          <FolderOpenIcon fontSize="small" />
                        ) : (
                          <Typography variant="caption" color="primary" mt={0.125}>
                            {t(ChartGroupTextDef.get(nodeId) as string)}
                          </Typography>
                        )}
                      </Link>
                    ))}
                  {/* ここにBoxを入れることで、Breadcrumbsの末尾にセパレーターをつけている */}
                  <Box></Box>
                </Breadcrumbs>
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <List component={"div"} dense sx={{ pt: 0 }}>
            {currentNodeId !== "root" && (
              <ListSubheader
                disableSticky
                component={"div"}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip placement="left" title={t(ChartGroupTextDef.get(currentNodeId) as string)} arrow>
                  <Typography variant="caption">{t(ChartGroupTextDef.get(currentNodeId) as string)}</Typography>
                </Tooltip>
              </ListSubheader>
            )}
            {getChildrenById(currentNodeId)?.map((node) => (
              <ChartListItem
                key={node.id}
                Icon={node.type === "group" ? Folder : BarChartIcon}
                hasLowerLevel={node.type === "group"}
                onClick={node.type === "group" ? () => setCurrentNodeId(node.id) : () => onClickAddChart(node.id)}
                text={
                  node.type === "group"
                    ? t(ChartGroupTextDef.get(node.id) as string)
                    : t(ChartTextDef.get(node.id) as string)
                }
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

type ChartListItemProps = {
  Icon: React.ElementType;
  hasLowerLevel: boolean;
  onClick: () => void;
  text: string;
};

const ChartListItem = ({ Icon, hasLowerLevel, onClick, text }: ChartListItemProps) => (
  <ListItem disablePadding onClick={onClick}>
    <Tooltip
      placement="left"
      title={text}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -20],
              },
            },
          ],
        },
      }}
    >
      <ListItemButton>
        <Icon fontSize="small" sx={{ mr: 1 }} />
        <ListItemText
          primary={
            <Typography fontSize="12px" noWrap>
              {text}
            </Typography>
          }
        />
        {hasLowerLevel && <ChevronRightIcon />}
      </ListItemButton>
    </Tooltip>
  </ListItem>
);
