import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Control, Controller, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { stringWidthUtil } from "../../../utils/string-width";
import { DashboardStore } from "../stores/dashboard-store";

export type DashboardBoardFormModel = {
  name: string;
};

/**
  ボードの名前を編集するフォーム。

  本コンポーネントは、useDashboardNameForm から生成されることを想定しています。
  使うときは、useDashboardNameForm から生成された DashboardNameForm を使ってください。


  フォーカスが外れているときは{@param initialName}で指定された文字が表示されます。
  ホバーするとフォームのような見た目になり、
  フォーカスをするとフォームが表示され、編集できるようになります。
  フォームからフォーカスが外れるとフォームが文字列になります。
*/
export const DashboardNameForm = ({
  initialName,
  control,
  setValueToNameForm,
  triggerNameForm,
}: {
  initialName?: string;
  control: Control<DashboardBoardFormModel, unknown>;
  setValueToNameForm: UseFormSetValue<DashboardBoardFormModel>;
  triggerNameForm: UseFormTrigger<DashboardBoardFormModel>;
}) => {
  const { setFormDirty } = DashboardStore.useContainer();
  const { t } = useTranslation();
  const [isEditingName, setIsEditingName] = useState(false);
  const [isHoveredName, setIsHoveredName] = useState(false);

  // ボード名の編集モードの切り替え
  const handleClick = () => {
    setIsEditingName(true);
  };

  // フォーカスが外れたらボード名の編集モードを終了
  const handleBlur = (name: string) => {
    if (!name) {
      setValueToNameForm("name", initialName || (t("dashboard.board.name.untitled") as string));
      triggerNameForm();
    }
    if (name !== initialName) {
      setFormDirty(true);
    }

    setIsEditingName(false);
  };

  return (
    <Controller
      name="name"
      control={control}
      render={({ field, fieldState }) => (
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            borderColor: isEditingName
              ? (theme) => theme.palette.grey[700]
              : isHoveredName
              ? (theme) => theme.palette.grey[400]
              : "transparent",
            p: "5px",
            display: "inline-block",
            cursor: "pointer",
            width: "fit-content",
          }}
          onMouseEnter={() => setIsHoveredName(true)}
          onMouseLeave={() => setIsHoveredName(false)}
          onClick={handleClick}
        >
          {isEditingName || !field.value ? (
            <TextField
              {...field}
              variant="standard"
              placeholder={t("placeholder.board-name") as string}
              error={Boolean(fieldState.error)}
              onBlur={(event) => {
                handleBlur(event.target.value);
                field.onBlur();
              }}
              autoFocus
              fullWidth
              InputProps={{
                style: {
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  padding: 0,
                  paddingTop: 0.8, // フォーカス当てたときのガタツキを防ぐため
                  paddingBottom: 0.8, // フォーカス当てたときのガタツキを防ぐため
                  width: `${Math.max(
                    200,
                    stringWidthUtil.calculateTextWidth(field.value || (t("placeholder.board-name") as string))
                  )}px`,
                },
                disableUnderline: true,
              }}
              inputProps={{
                maxLength: 80,
              }}
            />
          ) : (
            <Typography variant="h6" py={0.5} sx={{ minWidth: 200 }}>
              {field.value}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
