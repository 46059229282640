import { stringWidthUtil } from "./string-width";

const AUTO_HIDE_DURATION_LONG = 10000; // 10秒
const AUTO_HIDE_DURATION_SHORT = 1000; // 1秒
const CLOSE_ICON_WIDTH = 110;

const getCssWidthInPixel = (message: string, margin: number = CLOSE_ICON_WIDTH): string =>
  `${stringWidthUtil.getPixel(message) + margin}px`;

export const snackbarUtil = {
  AUTO_HIDE_DURATION_LONG,
  AUTO_HIDE_DURATION_SHORT,
  getCssWidthInPixel,
};
