import DashboardIcon from "@mui/icons-material/Dashboard";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export type DashboardNoDataProps = {
  title?: string;
  description: string;
  onClickEdit?: () => void;
};

export const DashboardNoData = ({ title, description, onClickEdit }: DashboardNoDataProps) => {
  const { t } = useTranslation();
  return (
    <Box alignItems="center" textAlign="center">
      <Box
        mb={1}
        sx={{
          display: "inline-flex",
          bgcolor: "common.white",
          borderRadius: "50%",
          width: 120,
          height: 120,
        }}
      >
        <DashboardIcon
          sx={{
            p: 2,
            color: (theme) => theme.palette.grey[400],
            fontSize: 120,
          }}
        />
      </Box>
      {title && <Typography fontSize={24}>{title}</Typography>}
      <Typography variant="body1" mt={title ? 0 : 3}>
        {description}
      </Typography>
      {onClickEdit && (
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={onClickEdit}>
            {t("dashboard.board.edit") as string}
          </Button>
        </Box>
      )}
    </Box>
  );
};
