/* eslint-disable @typescript-eslint/no-explicit-any */
import Highcharts from "highcharts";
import { TFunction } from "i18next";
import { SegmentChart } from "../../../../types";
import { numberUtil } from "../../../../utils/number";
import { DashboardChartDisplaySwitchModel } from "../../components/dashboard-chart-display-switch";
import { CHART_HEIGHT_IN_BOARD, CHART_MARGIN_TOP_IN_BOARD, OUR_COMPANY_COLORS } from "../../config/const";
import { ChartUnitTextDef, SegmentChartLegendTextDef } from "../../config/text-def";
import { SegmentChartQueryResult } from "../../dashboard-api";
import { Unit } from "../../types";
import { IBaseSegmentChart } from "../i-base-segment-chart";

export type BaseSegmentChartProps = {
  chart: SegmentChart;
  unitCaption?: string;
};

export class BaseSegmentChart implements IBaseSegmentChart {
  private static instances: Map<string, BaseSegmentChart> = new Map();
  private _unitCaption?: string;

  constructor({ unitCaption }: BaseSegmentChartProps) {
    this._unitCaption = unitCaption;
  }
  /**
   * 再描画を抑制するためにシングルトンパターンを利用
   * 参考: https://typescript-jp.gitbook.io/deep-dive/main-1/singleton
   */
  static getInstance<TChartClass extends BaseSegmentChart>(
    this: new (props: BaseSegmentChartProps) => TChartClass,
    props: BaseSegmentChartProps
  ): TChartClass {
    const key = JSON.stringify(props);
    if (!BaseSegmentChart.instances.has(key)) {
      const instance = new this(props);
      BaseSegmentChart.instances.set(key, instance);
    }
    return BaseSegmentChart.instances.get(key) as TChartClass;
  }

  /**
   * 各チャートでベースとなるオプションを作成
   * 必要であれば呼び出しもとで上書きする（seriesの上書きは必須）
   */
  getChartOptions(
    t: TFunction,
    queryResult: SegmentChartQueryResult,
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    // ツールチップに表示される値の桁区切りを設定
    Highcharts.setOptions({
      lang: {
        thousandsSep: ",",
      },
    });

    return {
      ...this._getChartOptions(t, queryResult, displaySwitch),
    };
  }

  private _getChartOptions(
    t: TFunction,
    queryResult: SegmentChartQueryResult,
    displaySwitch: DashboardChartDisplaySwitchModel
  ): Highcharts.Options {
    return {
      credits: { enabled: false },
      title: { text: "" },
      chart: {
        height: CHART_HEIGHT_IN_BOARD,
        marginTop: CHART_MARGIN_TOP_IN_BOARD,
        type: "bar",
      },
      //画面右の凡例表示
      responsive: {},
      legend: {
        y: 5,
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        itemStyle: {
          fontWeight: "normal",
        },
        // 棒グラフの凡例の形を四角形にする
        symbolWidth: 10,
        symbolHeight: 10,
        symbolRadius: 0,
        itemHiddenStyle: {
          // グラフ非表示時に凡例の取り消し線を無効にする
          textDecoration: "none",
        },
      },
      xAxis: {
        visible: queryResult.datasets.length > 0,
        categories: queryResult.datasets.map(({ index }) => index),
        tickLength: 0, // X軸の目盛線を非表示
        min: 0, // minとmaxを指定しないとnoDataの場合にX軸の目盛が表示されないため設定
        max: queryResult.datasets.length - 1,
      },
      yAxis: {
        title: { text: "" },
        min: 0,
        // 取得したデータが0だった場合、グラフの中央にy軸の0に対応する軸が描画されるため
        // `softMax: 1`を追加している。
        softMax: 1,
        labels: {
          formatter: function () {
            if (this.isFirst) {
              return `${this.value} (${t(ChartUnitTextDef.get(queryResult.unit as Unit) as string)})`;
            }
            return `${numberUtil.formatWithCommas(this.value as number)}`;
          },
        },
      },
      plotOptions: {
        series: {
          dataLabels: { enabled: displaySwitch.showDataLabel },
        },
      },
      lang: {
        noData: t("no-data") as string,
      },
      series: [
        {
          type: "bar",
          name: t(SegmentChartLegendTextDef.get(queryResult.legend) as string) as string,
          color: OUR_COMPANY_COLORS[0],
          data: queryResult.datasets.map((dataset) => dataset.value ?? null),
          showInLegend: queryResult.datasets.some((d) => d !== null),
        },
      ],
    };
  }

  noData(queryResult: SegmentChartQueryResult): boolean {
    return queryResult.datasets.length === 0;
  }

  get unitCaption() {
    return this._unitCaption;
  }
}
