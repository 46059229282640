import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CHARACTER_LIMIT } from "../../../config/const";
import { DashboardBoardFormModel, DashboardNameForm } from "../components/dashboard-name-form";

export const useDashboardNameForm = (name?: string) => {
  const { t } = useTranslation();

  const {
    handleSubmit: handleSubmitNameForm,
    control,
    getValues,
    setValue: setValueToNameForm,
    trigger: triggerNameForm,
  } = useForm<DashboardBoardFormModel>({
    defaultValues: {
      name,
    },
    resolver: yupResolver(
      yup.object<yup.AnyObject, Record<keyof DashboardBoardFormModel, yup.AnySchema>>({
        name: yup
          .string()
          .max(CHARACTER_LIMIT["80"], t("validation.character.limit", { number: CHARACTER_LIMIT["80"] }) as string)
          .required(t("validation.required") as string)
          .defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (name) {
      setValueToNameForm("name", name);
      triggerNameForm();
    }
  }, [name, setValueToNameForm, triggerNameForm]);

  const resetForm = () => {
    setValueToNameForm("name", name || "");
    triggerNameForm();
  };

  const Form = useCallback(() => {
    return (
      <DashboardNameForm
        initialName={name}
        control={control}
        setValueToNameForm={setValueToNameForm}
        triggerNameForm={triggerNameForm}
      />
    );
  }, [name, control, setValueToNameForm, triggerNameForm]);
  return {
    DashboardNameForm: Form,
    getName: () => getValues("name"),
    resetForm,
    handleSubmitNameForm,
  };
};
